import React, { Component } from 'react';
import { Alert, Button, Form, Row, Col } from 'react-bootstrap/';
import auth from './authService';
import axios from '../axios';
import Navbar from './Navbar';
import { withRouter } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import NumberFormat from 'react-number-format';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Loader from './Loader';
// import './payment_form/SquareFormStyle.css';
import { SquarePaymentForm, CreditCardNumberInput, CreditCardExpirationDateInput, CreditCardPostalCodeInput, CreditCardCVVInput, CreditCardSubmitButton } from 'react-square-payment-form';

class SignUp extends Component {
	state = {
		errorMessages: [],
		receivedToken: false,
		verifyObj: {
			amount: (0.0).toFixed(2),
			currencyCode: 'USD',
			intent: 'CHARGE',
			billingContact: {
				familyName: 'Smith',
				givenName: 'John',
				email: 'jsmith@example.com',
				country: 'GB',
				city: 'London',
				addressLines: ["1235 Emperor's Gate"],
				postalCode: 'SW7 4JA',
				phone: '020 7946 0532',
			},
		},
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		result: null,
		roles: null,
		month: null,
		year: null,
		checkDate: true,
		cvc: '',
		address: '',
		phone: '',
		cardError: '',
		country: '',
		region: '',
		number: '',
		loader: '',
		userAuthId: '',
		organizationRoute: this.props.match.params.OrgID,
		monthArray: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	};
	year() {
		var x = [];
		var a = new Date().getFullYear().toString();
		a = parseInt(a);

		for (var i = a; i < a + 11; i++) {
			x.push(i.toString());
		}

		return x;
	}
	handleYear = (e) => {
		this.setState({ year: e });
	};
	handleMonth = (e) => {
		this.setState({ month: e });
	};
	handleInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	cardNonceResponseReceived = (errors, nonce, cardData, buyerVerificationToken) => {
		console.log(errors, nonce);
		this.setState({ loader: true });
		if (errors) {
			this.setState({ errorMessages: errors.map((error) => error.message), loader: false });
			return;
		}
		this.setState({ errorMessages: [] });
		console.log(nonce, cardData, buyerVerificationToken);

		const userObject = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			pass: this.state.password,
			country: this.state.country + ' , ' + this.state.region,
			roleId: this.state.roles.filter((role) => role.name === 'customer')[0]._id,
			adminId: this.state.adminSetting.auth._id,
			// adminAuth: this.state.adminSetting.auth._id,
		};

		axios
			.post('/auth/signup', userObject)
			.then(async (response) => {
				console.log('response', response);
				auth.loginWithJWT(response.data.token);
				console.log('User', auth.getCurrentUser());
				axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
				const user = auth.getCurrentUser();
				let paymentDetails = {
					nonce: nonce,
					authId: user.id,
					email: user.email,
				};
				let square = await axios.post('/squareDetails/add', paymentDetails);
				if (square.status === 201) {
					let route = `/${this.state.organizationRoute}`;
					window.location = route;
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({ result: err.response.data.message, loader: false });
			});
	};
	createVerificationDetails(obj) {
		return obj;
	}

	selectCountry(val) {
		this.setState({ country: val });
	}
	selectRegion(val) {
		this.setState({ region: val });
	}
	componentDidMount() {
		console.log(this.props.history);
		console.log(this.props.match.params.OrgID);
		let route = this.props.match.params.OrgID;
		console.log(route);
		this.setState({
			organizationRoute: this.props.match.params.OrgID,
		});
		console.log('Sign In component', this.state.organizationRoute);
		axios
			.get('/admin/getAll')
			.then((response) => {
				console.log('organization details ', response.data.organizations);
				response.data.organizations.filter((element) => {
					console.log(element.auth.route, this.state.organizationRoute);
					if (element.auth.route === this.state.organizationRoute) {
						this.setState({ adminSetting: element }, () => {
							console.log('Admin Setting ', this.state);
						});
					}
					return 0;
				});
			})
			.catch((err) => {
				console.log(err);
				console.log('Sign Up organization details Error', err.response);
			});

		axios
			.get('/roles')
			.then((response) => {
				this.setState({ roles: response.data.roles }, () => {
					console.log('ROLES', this.state.roles.filter((role) => role.name === 'customer')[0]._id);
				});
			})
			.catch((err) => console.log('Error', err));
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	handleSubmit = async (e) => {
		e.preventDefault();
		this.setState({ result: null, loader: true });
		console.log(this.state);
		if (this.state.adminSetting.stripeEnabled) {
			if (this.state.month === null || this.state.year === null) {
				console.log('Missing Values');
				this.setState({ checkDate: false });
				return;
			} else {
				this.setState({ checkDate: true });
			}

			if (this.state.number === '' || this.state.number.split(' ').join('').length < 16) {
				this.setState({
					cardError: true,
				});
				return;
			} else {
				this.setState({
					cardError: false,
				});
			}
		}

		const userObject = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			pass: this.state.password,
			country: this.state.country + ' , ' + this.state.region,
			roleId: this.state.roles.filter((role) => role.name === 'customer')[0]._id,
			adminId: this.state.adminSetting.auth._id,
			// adminAuth: this.state.adminSetting.auth._id,
		};

		if (this.state.userAuthId) {
			try {
				if (this.state.adminSetting.stripeEnabled) {
					let data = {
						cardNumber: this.state.number.split(' ').join(''),
						expMonth: this.state.monthArray.indexOf(this.state.month) + 1,
						expYear: this.state.year,
						cvc: this.state.cvc,
						authId: this.state.userAuthId,
					};
					let stripe = await axios.post('/stripeDetails/add', data);
					if (stripe.status === 201) {
						// let route = '/' + this.state.organizationRoute + '/signin';
						let route = `/${this.state.organizationRoute}`;
						window.location = route;
					}
				}
			} catch (err) {
				console.log(err);
				this.setState({ result: err.response.data.message, loader: false });
			}
		} else {
			axios
				.post('/auth/signup', userObject)
				.then(async (response) => {
					console.log('response', response);
					auth.loginWithJWT(response.data.token);
					console.log('User', auth.getCurrentUser());
					const user = auth.getCurrentUser();
					this.setState({ userAuthId: user.id });
					if (this.state.adminSetting.stripeEnabled) {
						let data = {
							cardNumber: this.state.number.split(' ').join(''),
							expMonth: this.state.monthArray.indexOf(this.state.month) + 1,
							expYear: this.state.year,
							cvc: this.state.cvc,
							authId: user.id,
						};
						let stripe = await axios.post('/stripeDetails/add', data);
						if (stripe.status === 201) {
							// let route = '/' + this.state.organizationRoute + '/signin';
							let route = `/${this.state.organizationRoute}`;
							window.location = route;
						}
					}
				})
				.catch((err) => {
					console.log(err);
					this.setState({ result: err.response.data.message, loader: false });
				});
		}
	};
	render() {
		if (this.state.adminSetting)
			return (
				<Row>
					<Col>
						<Row className='p-0'>
							<Col md={12} lg={12} sm={12} className='p-0 '>
								<Navbar user={this.state.user} adminSetting={this.state.adminSetting} />
							</Col>
						</Row>
						<Row>
							<Col>
								<h1>Sign Up</h1>

								<Row>
									<Col lg={6} md={6}>
										<Form.Group>
											<Form.Label>First Name</Form.Label>
											<Form.Control type='firstName' onChange={this.handleChange} required name='firstName' value={this.state.firstName} placeholder='Enter First Name' />
										</Form.Group>
									</Col>
									<Col lg={6} md={6}>
										<Form.Group>
											<Form.Label>Last Name</Form.Label>
											<Form.Control type='lastName' onChange={this.handleChange} required name='lastName' value={this.state.lastName} placeholder='Enter Last Name' />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col lg={6} md={6}>
										<Form.Group>
											<Form.Label>Email address</Form.Label>
											<Form.Control type='email' onChange={this.handleChange} required name='email' value={this.state.email} placeholder='Enter Email' />
										</Form.Group>
									</Col>
									<Col lg={6} md={6}>
										<Form.Group>
											<Form.Label>Password</Form.Label>
											<Form.Control type='password' onChange={this.handleChange} required name='password' value={this.state.password} placeholder='Enter Password' />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col lg={6} md={6}>
										<Form.Group>
											<Form.Label>Address</Form.Label>
											<Form.Control type='text' onChange={this.handleChange} required name='address' value={this.state.address} placeholder='Enter Address' />
										</Form.Group>
									</Col>
									<Col lg={6} md={6}>
										<Form.Group>
											<Form.Label>Phone</Form.Label>
											<Form.Control type='text' onChange={this.handleChange} required name='phone' value={this.state.phone} placeholder='Enter Phone' />
										</Form.Group>
									</Col>
									<Col lg={6} md={6}>
										<Form.Label>Region</Form.Label>
										<br />
										<CountryDropdown className='country-dropdown' value={this.state.country} onChange={(val) => this.selectCountry(val)} />
									</Col>
									<Col lg={6} md={6}>
										<Form.Label>Country</Form.Label>
										<br />
										<RegionDropdown className='region-dropdown' country={this.state.country} value={this.state.region} onChange={(val) => this.selectRegion(val)} />
									</Col>
								</Row>

								{this.state.adminSetting.stripeEnabled ? (
									<Row className='mb-4 mt-5'>
										<Col lg={6} md={6} xs={10} style={{ paddingTop: '1rem', paddingBottom: '1rem', backgroundColor: '#9FA8A3' }}>
											<Row className='contentCenter'>
												<Col>
													<h3 style={{ textAlign: 'center' }}>Payment Information</h3>
												</Col>
											</Row>
											<Row>
												<Col lg={12} md={12} xs={12}>
													<NumberFormat customInput={Form.Control} format='#### #### #### ####' name='number' placeholder='Card Number' value={this.state.number} onChange={this.handleInputChange} />
												</Col>
											</Row>

											<Row>
												<Col md={4} xs={4} lg={4}>
													<NumberFormat customInput={Form.Control} format='###' name='cvc' className='mt-2' placeholder='CVC' value={this.state.cvc} onChange={this.handleInputChange} />
												</Col>
											</Row>

											<Row className='mt-3'>
												<Col md={4} lg={4} xs={4}>
													<h6 className='pt-2'>Expiry Date</h6>
												</Col>

												<Col md={4} lg={4} xs={4}>
													<DropdownButton variant='info' onSelect={this.handleMonth} title={this.state.month ? <span style={{ color: 'white' }}>{this.state.month}</span> : <span style={{ color: 'white' }}>Month</span>}>
														{this.state.monthArray.map((month) => {
															return (
																<Dropdown.Item style={{ backgroundColor: '#17a2b8', color: 'white' }} eventKey={month}>
																	{month}
																</Dropdown.Item>
															);
														})}
													</DropdownButton>
												</Col>
												<Col md={4} lg={4} xs={4}>
													<DropdownButton variant='info' onSelect={this.handleYear} id='dropdown-basic-button' title={this.state.year ? <span style={{ color: 'white' }}>{this.state.year}</span> : <span style={{ color: 'white' }}>Year</span>}>
														{this.year().map((year) => {
															return (
																<Dropdown.Item style={{ backgroundColor: '#17a2b8', color: 'white' }} eventKey={year}>
																	{year}
																</Dropdown.Item>
															);
														})}
													</DropdownButton>
												</Col>
											</Row>
											{!this.state.checkDate && <Alert variant='danger'>Select Month Or Year</Alert>}
											{this.state.cardError && <Alert variant='danger'>Card Number Should Be valid</Alert>}
										</Col>
									</Row>
								) : (
									<Row>
										<SquarePaymentForm
											inputStyles={[
												{
													backgroundColor: '#fff',
												},
												{
													color: '#373F4A',
												},
												{
													fontFamily: 'Helvetica Neue',
												},
												{
													fontSize: '16px',
												},
												{
													lineHeight: '35px',
													// lineHeight: '16px',
												},
												{
													padding: '0px 10px',
												},
											]}
											placeholderCreditCard='Card Number'
											placeholderPostal='Postal'
											sandbox={false}
											applicationId={this.state.adminSetting.squareAppId}
											locationId={this.state.adminSetting.squareLocationId}
											// applicationId='sandbox-sq0idb-YcaIZUWCibfLpJD9VLAtfg'
											// locationId='LQTJ5APXQYJKQ'
											cardNonceResponseReceived={this.cardNonceResponseReceived}
											createVerificationDetails={() => this.createVerificationDetails(this.state.verifyObj)}>
											<Col lg={6} md={6} xs={12} style={{ backgroundColor: '#9FA8A3' }}>
												<Row className='contentCenter mt-2'>
													<Col>
														<h3 style={{ textAlign: 'center' }}>Payment Information</h3>
													</Col>
												</Row>

												<Row>
													<Col>
														<Row>
															<Col lg={12} md={12} xs={12} className='sq-fieldset ml-3'>
																<CreditCardNumberInput placeholderCreditCard='Card Number' />
															</Col>
														</Row>
														<Row className='sq-fieldset'>
															<Col className='sq-form-third' lg={3} md={3} xs={3}>
																<CreditCardCVVInput />
															</Col>
														</Row>
														<Row className='sq-fieldset'>
															<Col className='sq-form-third sq-form-third-1' lg={3} md={3} xs={12}>
																Expiry Date
															</Col>
															<Col className='sq-form-third sq-form-third-2' lg={4} md={4} xs={12}>
																<CreditCardExpirationDateInput />
															</Col>
															<Col className='sq-form-third' lg={3} md={3} xs={12}>
																<CreditCardPostalCodeInput placeholderPostal='Postal' />
															</Col>
														</Row>
													</Col>
												</Row>
												{this.state.loader && (
													<Row>
														<Col>
															<Loader />
														</Col>
													</Row>
												)}

												<Row>
													<Col lg={6} md={6} xs={6}>
														<div className='sq-error-message mb-4'>
															{this.state.errorMessages.map((errorMessage) => (
																<li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
															))}
														</div>
													</Col>
												</Row>
											</Col>
											<Row>
												<Col lg={6} sm={6} xs={6}>
													{this.state.firstName === '' || this.state.lastName === '' || this.state.email === '' || this.state.phone === '' ? (
														<Button disabled variant='primary' size='lg' style={{ marginTop: '.5rem' }} block type='submit'>
															Sign Up
														</Button>
													) : (
														<CreditCardSubmitButton disabled={true} variant='primary' size='lg' block>
															Sign Up
														</CreditCardSubmitButton>
													)}
												</Col>
											</Row>
										</SquarePaymentForm>
									</Row>
								)}
								<Row className='mt-2'>
									<Col lg={6} md={6}>
										{this.state.adminSetting.stripeEnabled ? (
											<Button onClick={this.handleSubmit} variant='primary' size='lg' block type='submit'>
												Sign Up
											</Button>
										) : null}

										<br />
										{this.state.result && (
											<h5>
												<Alert variant='danger'>{this.state.result}</Alert>
											</h5>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			);
		else return <h2>No organization found</h2>;
	}
}

export default withRouter(SignUp);
