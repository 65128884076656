import React from 'react';
import './SquareFormStyle.css';
import { Alert, Button, Row, Col } from 'react-bootstrap/';
import { SquarePaymentForm, CreditCardNumberInput, CreditCardExpirationDateInput, CreditCardPostalCodeInput, CreditCardCVVInput, CreditCardSubmitButton } from 'react-square-payment-form';
import Loader from '../Loader';
export default class SquareForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMessages: [],
			loader: false,
			receivedToken: false,
			verifyObj: {
				amount: props.total.toFixed(2),
				currencyCode: 'USD',
				intent: 'CHARGE',
				billingContact: {
					familyName: 'Smith',
					givenName: 'John',
					email: 'jsmith@example.com',
					country: 'GB',
					city: 'London',
					addressLines: ["1235 Emperor's Gate"],
					postalCode: 'SW7 4JA',
					phone: '020 7946 0532',
				},
			},
		};
	}

	cardNonceResponseReceived = (errors, nonce, cardData, buyerVerificationToken) => {
		this.props.loaderSet();
		if (errors) {
			this.setState({ errorMessages: errors.map((error) => error.message), loader: false });
			return;
		}
		this.setState({ errorMessages: [] });
		console.log(nonce, cardData, buyerVerificationToken);
		if (!this.props.update) {
			this.setState({ receivedToken: true });
		}
		this.props.sentData({
			cardData,
			nonce: nonce,
			buyerVerificationToken: buyerVerificationToken,
		});
	};
	createVerificationDetails(obj) {
		return obj;
	}

	render() {
		return (
			<Row>
				<Col lg={6} md={6} xs={12} style={{}}>
					<div
						style={{
							backgroundColor: '#9FA8A3',
							margin: '1rem',
							padding: '1rem',
						}}>
						<Row className='contentCenter mt-2'>
							<Col>
								<h3 style={{ textAlign: 'center' }}>Payment Information</h3>
							</Col>
						</Row>
						<Row>
							<Col className='contentCenter'>
								<SquarePaymentForm
									inputStyles={[
										{
											backgroundColor: '#fff',
										},
										{
											color: '#373F4A',
										},
										{
											fontFamily: 'Helvetica Neue',
										},
										{
											fontSize: '16px',
										},
										{
											lineHeight: '35px',
											// lineHeight: '16px',
										},
										{
											padding: '0px 10px',
										},
									]}
									placeholderCreditCard='Card Number'
									placeholderPostal='Postal'
									sandbox={true}
									applicationId={this.props.accountId}
									locationId={this.props.locationId}
									// applicationId='sandbox-sq0idb-YcaIZUWCibfLpJD9VLAtfg'
									// locationId='LQTJ5APXQYJKQ'
									cardNonceResponseReceived={this.cardNonceResponseReceived}
									createVerificationDetails={() => this.createVerificationDetails(this.state.verifyObj)}>
									<Row>
										<Col lg={12} md={12} xs={12} className='sq-fieldset ml-3'>
											<CreditCardNumberInput placeholderCreditCard='Card Number' />
										</Col>
									</Row>
									<Row className='sq-fieldset'>
										<Col className='sq-form-third' lg={3} md={3} xs={3}>
											<CreditCardCVVInput />
										</Col>
									</Row>
									<Row className='sq-fieldset'>
										<Col className='sq-form-third sq-form-third-1' lg={3} md={3} xs={12}>
											Expiry Date
										</Col>
										<Col className='sq-form-third sq-form-third-2' lg={4} md={4} xs={12}>
											<CreditCardExpirationDateInput />
										</Col>
										<Col className='sq-form-third' lg={3} md={3} xs={12}>
											<CreditCardPostalCodeInput placeholderPostal='Postal' />
										</Col>
									</Row>

									{this.props.update ? (
										<Col lg={12} md={12} xs={12} className='button11'>
											{/* <Button className='mt-4 mb-4 ' variant='info' type='submit' size='lg' block>
												Update
											</Button> */}
											<CreditCardSubmitButton className='mt-4 mb-4'>Update</CreditCardSubmitButton>
										</Col>
									) : (
										!this.state.receivedToken && (
											<Row className='button1'>
												<Col lg={12} md={12} xs={12} className='button1'>
													<CreditCardSubmitButton className='button1 button3 btn-hover'>{this.props.loader ? <Loader /> : <span>Give Now&nbsp;: &nbsp;$&nbsp; {this.props.total}</span>}</CreditCardSubmitButton>
												</Col>
												<Col lg={12} md={12} xs={12}>
													<Button onClick={() => this.props.backButton()} className='mt-4 mb-4 ' variant='info' type='submit' size='lg' block>
														Back
													</Button>
												</Col>
											</Row>
										)
									)}

									{this.state.receivedToken && (
										<Row>
											<br />
											<Col lg={12} md={12} xs={12}>
												{this.state.receivedToken && <Loader />}

												<Button onClick={() => this.props.backButton()} className='mt-1 mb-1' variant='info' type='submit' size='lg' block>
													Back
												</Button>

												{/* <Alert variant='success'> Successful payment</Alert> */}
											</Col>
										</Row>
									)}
								</SquarePaymentForm>
							</Col>
						</Row>
						<Row>
							<Col lg={10} md={8} sx={10}>
								<div className='sq-error-message mb-4'>
									{this.state.errorMessages.map((errorMessage) => (
										<li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
									))}
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		);
	}
}
