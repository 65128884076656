import React, { Component } from 'react';
import { Button, Alert, Row, Col } from 'react-bootstrap/';
import Navbar from '../Navbar';
import auth from '../authService';
import axios from '../../axios';
import MaterialTable from 'material-table';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import SubscribeIcon from '@material-ui/icons/Subscriptions';
import StriptForm from '../payment_form/StripeForm';
import SquareForm from '../payment_form/SquareForm';
export class Profile extends Component {
	state = {
		user: auth.getCurrentUser(),
		adminSetting: this.props.admin,
		payments: [],
		userPaymentInfo: [],
		error: '',
		msg: '',
		showCardForm: false,
		monthArray: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	};

	componentDidMount() {
		console.log(this.state.user);
		if (this.state.user) {
			axios
				.get(`/payment/getByCustomer/${this.state.user.id}`)
				.then((response) => {
					// console.log(response);
					this.setState({
						payments: response.data.details,
					});
				})
				.catch((err) => {
					console.log(err);
				});
			let url = this.state.adminSetting.stripeEnabled ? 'stripeDetails/getByCustomer' : 'squareDetails/getByCustomer';
			axios
				.get(`${url}/${this.state.user.id}`)
				.then((response) => {
					// console.log(response);
					this.setState({
						userPaymentInfo: response.data.details,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	unsubscribePayment = async () => {
		// alert(JSON.stringify(this.state.user))
		try {
			let sub = await axios.patch('/admin/changeSubscription?customer=' + this.state.user.id);
			// console.log(sub.data);
			if (sub.status === 201) {
				this.setState({
					msg: sub.data.message,
				});
				// window.location.href=`/user${this.state.adminSetting.auth.route}/profile`
			}
		} catch (err) {
			console.log(err);
			this.setState({
				error: err.response.message,
			});
		}
	};

	getStripeFormData = async (data) => {
		console.log(data);
		if (data) {
			let { number, month, year, cvc } = data;
			data = {
				cardNumber: number.split(' ').join(''),
				expMonth: this.state.monthArray.indexOf(month) + 1,
				expYear: year,
				cvc: cvc,
				authId: this.state.user ? this.state.user.id : null,
			};

			try {
				let update = await axios.patch(`/stripeDetails/update/${this.state.userPaymentInfo._id}`, data);
				if (update.status === 201) {
					console.log(update.data.message);
					this.setState({
						msg: update.data.message || update.data.messgae,
						showCardForm: false,
						error: '',
					});
				}
			} catch (err) {
				console.log(err);
				console.log(err.response.data.message);
				if (err.response.data.message) {
					this.setState({
						error: err.response.data.message,
					});
				} else {
					this.setState({
						error: err.response.message,
					});
				}
			}
		}
	};
	getSquareFormData = async (data) => {
		console.log(data);
		if (data) {
			let { nonce } = data;
			data = {
				nonce: nonce,
				authId: this.state.user.id,
				email: this.state.user.email,
			};

			try {
				let update = await axios.patch(`/squareDetails/update/${this.state.userPaymentInfo._id}`, data);
				console.log(update.data);
				if (update.status === 201) {
					this.setState({
						msg: update.data.message,
						showCardForm: false,
						error: '',
					});
				}
			} catch (err) {
				console.log(err);
				this.setState({
					error: err.response.message,
				});
			}
		}
	};
	updateCard = () => {
		this.setState((prevState) => {
			return {
				msg: '',
				showCardForm: !prevState.showCardForm,
			};
		});
	};

	editCardInfo = async () => {};

	render() {
		return (
			<>
				<Row>
					<Col>
						<Row className='p-0'>
							<Col md={12} lg={12} sm={12} className='p-0 '>
								<Navbar showCard={this.updateCard} user={this.state.user} adminSetting={this.state.adminSetting} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col>
						{this.state.error && (
							<Alert dismissible={true} variant='danger'>
								{this.state.error}
							</Alert>
						)}
					</Col>
				</Row>
				{console.log(this.state)}
				<Row>
					<Col>
						{this.state.msg && (
							<Alert dismissible={true} variant='success'>
								{this.state.msg}
							</Alert>
						)}
					</Col>
				</Row>
				{this.state.showCardForm && (
					<Row lg={10} md={10} xs={10}>
						<Col lg={12} md={12} xs={12} className='mt-3 ml-3'>
							{this.state.adminSetting.stripeEnabled && <StriptForm update submitData={this.getStripeFormData} />}
							{!this.state.adminSetting.stripeEnabled && <SquareForm locationId={this.state.adminSetting.squareLocationId} accountId={this.state.adminSetting.squareAppId} update details={this.state} total={0} sentData={this.getSquareFormData} />}
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<div style={{ margin: '1rem', textAlign: 'right' }}>
							<Button onClick={this.unsubscribePayment} className='unsub_btn' variant='primary'>
								{this.state.user.subscription ? 'Unsubscribe' : 'Subscribe'}
							</Button>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<MaterialTable
							title='Payments'
							columns={[
								{ title: 'Fund Type', field: 'fundType' },
								{ title: 'Mode', field: 'type' },
								{ title: 'Amount', field: 'amount', type: 'numeric' },
								{ title: 'Status', field: 'status' },
								{ title: 'Description', field: 'description' },
								{ title: 'Date', field: 'date' },
							]}
							data={this.state.payments}
							actions={
								[
									// {
									// 	icon: 'unsubscribeIcon',
									// 	tooltip: 'Unsubscribe Payment',
									// 	onClick: (event, rowData) => alert('You saved ' + rowData.name),
									// },
								]
							}
							options={{
								actionsColumnIndex: -1,
								headerStyle: {
									backgroundColor: 'rgb(159, 168, 163)',
									color: '#000',
								},
							}}
						/>
					</Col>
				</Row>
			</>
		);
	}
}

export default Profile;
