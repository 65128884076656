import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { Redirect, Link, withRouter } from 'react-router-dom';
class Receipt extends Component {
	render() {
		return (
			<div className='mt-5'>
				<Row className='pl-4'>
					<Col md={10} lg={10} xs={12}>
						<h3>Receipt</h3>
						<hr />
						<Row className='mt-5'>
							<Col className='bold' md={4} xs={6}>
								Receipt Number
							</Col>
							<Col md={3} xs={6}>
								{this.props.receipt._id}
							</Col>
						</Row>
						<hr />

						<Row className='mt-5'>
							<Col md={4} xs={4}>
								<Row>
									<Col className='bold mb-3'>Date</Col>
								</Row>

								<Row>
									<Col>{this.props.receipt.date}</Col>
								</Row>
							</Col>
							{/* <Col md={3} xs={3}>
								<Row>
									<Col className='bold mb-3'>Description</Col>
								</Row>

								<Row>
									<Col>{this.props.receipt.description}</Col>
								</Row>
							</Col> */}
							<Col md={4} xs={4}>
								<Row>
									<Col className='bold mb-3'>Fund Type</Col>
								</Row>

								<Row>
									<Col>{this.props.receipt.fundType}</Col>
								</Row>
							</Col>
							<Col md={4} xs={4}>
								<Row>
									<Col className='bold mb-3'>Amount</Col>
								</Row>

								<Row>
									<Col>${this.props.receipt.amount}</Col>
								</Row>
							</Col>
						</Row>
						<hr />

						<Row className='mt-5'>
							<Col className='bold mb-3' md={4} xs={6}>
								Business Name
							</Col>
							<Col md={3} xs={3}>
								{this.props.receipt.businessName}
							</Col>
						</Row>
						<hr />
						<Row className='mt-5'>
							<Col className='bold mb-3' md={4} xs={6}>
								Business Number
							</Col>
							<Col md={3} xs={6}>
								{this.props.receipt.businessNumber}
							</Col>
						</Row>
						<hr />
					</Col>
				</Row>
			</div>
		);
	}
}
class Print extends React.Component {
	componentDidMount() {
		console.log(this.props.location.query);
		if (this.props.location.query) {
		} else {
			this.props.history.push('/');
		}
	}

	gotBack = () => {
		this.props.history.push('/' + this.props.location.query.link);
	};
	render() {
		return (
			<div>
				{this.props.location.query ? (
					<>
					{/* this.props.location.query */}
						<Receipt receipt={this.props.location.query } ref={(el) => (this.componentRef = el)} />
						<Row className='centerButton'>
							<ReactToPrint
								trigger={() => (
									<Button
										className='centerItem'
										style={{
											color: 'white',
											background: 'linear-gradient(to bottom,#004d40 0%, #009688 100%)',
											width:'10rem'
										}}>
										Save Receipt
									</Button>
								)}
								content={() => this.componentRef}
							/>
							<button
								className='centerItem btn'
								// to={`/${this.props.location.query.link}`}
								onClick={this.gotBack}
								style={{
									color: 'white',
									background: 'linear-gradient(rgb(0, 77, 64) 0%, rgb(0, 150, 136) 100%)',
									padding: '0.4rem 1rem',
									borderRadius: '.25rem',
									margin: '0 0.5rem',
									width:'10rem'
								}}>
								Back
							</button>
						</Row>
					</>
				) : null}
			</div>
		);
	}
}

export default withRouter(Print);
