import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './components/admin_panel/Dashboard';
import UserList from './components/admin_panel/UserList';
import EditProfile from './components/admin_panel/EditProfile';
import OrganizationHomePage from './components/OrganizationHomePage';
import './index.css';
import LandingPage from './components/LandingPage';
import StripeForm from './components/payment_form/StripeForm';
import SquareForm from './components/payment_form/SquareForm';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import auth from './components/authService';
import org from './components/admin_panel/Organization';
import { withRouter } from 'react-router-dom';
import axios from './axios';
import Navbar from './components/Navbar';
import SuperAdminDashboard from './components/admin_panel/Super Admin/SuperAdminDashboard';

import SuperAdminEditProfile from './components/admin_panel/Super Admin/SuperAdminEditProfile';
import SuperAdminPending from './components/admin_panel/Super Admin/SuperAdminPending';
import SuperAdminUserList from './components/admin_panel/Super Admin/SuperAdminUserList';
import Profile from './components/user_panel/Profile';
// import Receipt from "./components/Receipt";
import Print from './components/Receipt';

class App extends Component {
	state = {
		roles: [],
		organizationDetails: [],
		user: auth.getCurrentUser(),
		adminSetting: null,
	};
	componentDidMount() {
		axios
			.get('/admin/getAll')
			.then((response) => {
				console.log('organization details ', response.data.organizations);
				this.setState({ organizationDetails: response.data.organizations });
			})
			.catch((err) => {
				console.log('organization details', err.response);
			});

		axios
			.get('/roles')
			.then((response) => {
				console.log('Roles', response.data);
				this.setState({ roles: response.data.roles });
			})
			.catch((err) => {
				console.log('Roles Erro', err);
			});
	}
	refreshAdmin = () => {
		axios
			.get('/admin/getAll')
			.then((response) => {
				console.log('organization details ', response.data.organizations);
				this.setState({ organizationDetails: response.data.organizations });
			})
			.catch((err) => {
				console.log('organization details', err.response);
			});
	};
	render() {
		return (
			<BrowserRouter>
				<React.Fragment>
					<div className=' container pr-0 mainContainer' style={{ minHeight: '100vh',width:'100wh' }}>
						{/* <Navbar adminSetting={}/> */}
						<Switch>
							<Route exact path='/register' component={LandingPage} />

							{/* Exmaples*/}
							<Route exact path='/example4' component={StripeForm} />
							<Route exact path='/example41' component={SquareForm} />
							<Route exact path='/example3' component={EditProfile} />
							<Route exact path='/example1' component={UserList} />
							<Route exact path='/example2' component={Dashboard} />
							<Route exact path='/org' component={org} />

							{/* Super Admin routes */}
							<Route
								exact
								path='/superAdmin'
								render={(props) => {
									if (this.state.user && this.state.user.isSuperAdmin) return <SuperAdminDashboard />;
									else return <Redirect to='/admin-pci/SignIn' />;
								}}
							/>
							<Route
								exact
								path='/superAdminUserList'
								render={(props) => {
									if (this.state.user && this.state.user.isSuperAdmin) return <SuperAdminUserList />;
									else return <Redirect to='/admin-pci/SignIn' />;
								}}
							/>
							<Route
								exact
								path='/superAdminEditProfile'
								render={(props) => {
									if (this.state.user && this.state.user.isSuperAdmin) return <SuperAdminEditProfile />;
									else return <Redirect to='/admin-pci/SignIn' />;
								}}
							/>
							<Route
								exact
								path='/superAdminPending'
								render={(props) => {
									if (this.state.user && this.state.user.isSuperAdmin) return <SuperAdminPending />;
									else return <Redirect to='/admin-pci/SignIn' />;
								}}
							/>

							{/* Admin routes */}
							<Route
								exact
								path='/admin-pci'
								render={(props) => {
									if (this.state.user && this.state.user.isAdmin) return <Redirect to='/admin/dashboard' />;
									else return <Redirect to='/admin-pci/SignIn' />;
								}}
							/>
							<Route
								path='/admin-pci/dashboard'
								render={(props) => {
									if (this.state.user && this.state.user.isAdmin) return <Dashboard admin={this.state.adminSetting} />;
									else return <Redirect to='/admin-pci/SignIn' />;
								}}
							/>
							<Route
								path='/admin-pci/userlist'
								render={(props) => {
									if (this.state.user && this.state.user.isAdmin) return <UserList {...props} />;
									else return <Redirect to='/admin-pci/SignIn' />;
								}}
							/>
							<Route
								path='/admin-pci/editProfile'
								render={(props) => {
									if (this.state.user && this.state.user.isAdmin) return <EditProfile {...props} />;
									else return <Redirect to='/admin-pci/SignIn' />;
								}}
							/>
							<Route path='/admin-pci/SignIn' component={SignIn} />

							{this.state.organizationDetails.map((element, id) => {
								if (element.auth.route) {
									let route = element.auth.route;
									return (
										<Route
											key={id}
											exact
											path={'/user' + route + '/profile'}
											render={(props) => {
												if (this.state.user && !this.state.user.isAdmin) {
													return <Profile admin={element} refresh={this.refreshAdmin} />;
												} else {
													return <Redirect to={`${route}/signin`} />;
												}
											}}
										/>
									);
								}
							})}

							{/* Dynamic routes */}
							{this.state.organizationDetails.map((element, id) => {
								if (element.auth.route) {
									let route = '/' + element.auth.route;
									return (
										<Route
											key={id}
											exact
											path={route}
											render={(props) => {
												return <OrganizationHomePage admin={element} />;
											}}
										/>
									);
								}
							})}

							{/* Auth routes */}

							<Route
								path='/:OrgID/signin'
								render={(props) => {
									if (this.state.user) return <Redirect to='/' />;
									else return <SignIn {...props} />;
								}}
							/>

							<Route
								path='/:OrgID/signup'
								exact
								render={(props) => {
									if (this.state.user) return <Redirect to='/' />;
									else return <SignUp {...props} />;
								}}
							/>
							<Route exact path='/:Org/receipt' component={Print} />
							<Route exact path='/receipt' component={Print} />
						</Switch>
					</div>
				</React.Fragment>
			</BrowserRouter>
		);
	}
}

export default App;
