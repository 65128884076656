import React, { Component } from 'react';
import { Button, Alert, Form, Row, Col, Dropdown, DropdownButton, InputGroup } from 'react-bootstrap/';
import StriptForm from './payment_form/StripeForm';
import SquareForm from './payment_form/SquareForm';
import FundComponent from './FundComponent';
import axios from '../axios';
import auth from './authService';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import MaterialButton from '@material-ui/core/Button';
import Navbar from './Navbar';
import { Link, withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

class OrganizationHomePage extends Component {
	state = {
		monthArray: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		firstName: null,
		lastName: null,
		recurring: false,
		streetAddress: null,
		email: null,
		phoneNumber: null,
		zipCode: null,
		country: '',
		region: '',
		total: 0,
		funds: [{ fundType: '', amount: '', description: '' }],
		FormData: null,
		User: auth.getCurrentUser() && auth.getCurrentUser().route === this.props.admin.auth.route ? auth.getCurrentUser() : null,
		adminSetting: this.props.admin,
		missing: false,
		show: true,
		example: null,
		previousTotal: null,
		recurringwithLoginCheck: null,
		recurringwithLogin: false,
		error: '',
		recurringDuration: 'weeklyRecurring',
		user: auth.getCurrentUser(),
		success: false,
		loader: false,
		userAuthId: '',
	};
	handleExample = (e) => {
		if (e.target.value > 0 || e.target.value === '') this.setState({ example: e.target.value });
	};

	handleBlurExample = (e) => {
		var num = parseFloat(this.state.example);
		var cleanNum = num.toFixed(2);
		this.setState({ example: cleanNum });
	};

	selectCountry(val) {
		this.setState({ country: val });
	}
	selectRegion(val) {
		this.setState({ region: val });
	}

	componentDidMount() {
		// console.log(this.props);
		const { User } = this.state;
		// console.log("Home Admin Daynamic Props", this.state.adminSetting);
		// console.log('User,User', User);
		// console.log('Adminsetting homepage', this.state.adminSetting);

		if (User) {
			this.setState({ firstName: User.firstName });
			this.setState({ lastName: User.lastName });
			this.setState({});
		}
	}
	getSquareFormData = async (data) => {
		let payments = this.state.funds.map((fund) => {
			if (fund.description !== '') {
				return { ...fund };
			} else {
				return { ...fund, description: '' };
			}
		});
		try {
			if (data) {
				console.log('Square form Data', data);
				// console.log(this.state.recurringwithLoginCheck);
				if (this.state.recurringwithLoginCheck === 'recurringWithoutLogin') {
					// const newUser = await axios.post('/auth/getAuthId', { adminId: this.state.adminSetting.auth._id });
					// console.log(newUser.data.auth._id);
					if (this.state.userAuthId !== '') {
						let paymentDetails = {
							nonce: data.nonce,
							authId: this.state.userAuthId,
							email: this.state.email,
						};
						let square = await axios.post('/squareDetails/add', paymentDetails);
						if (square.status === 201) {
							// console.log(square);
							data = {
								authId: this.state.userAuthId,
								payments: payments,
								type: this.state.recurring ? this.state.recurringDuration : 'oneTime',
								email: this.state.email,
								country: this.state.country,
								region: this.state.region,
							};
							let payment = await axios.post('/payment/addUserPayment/' + this.state.adminSetting._id, data);
							// console.log(payment);
							if (payment.status === 200 || payment.status === 201) {
								this.setState({
									success: true,
									firstName: null,
									lastName: null,
									recurring: false,
									streetAddress: null,
									email: null,
									country: '',
									region: '',
									phoneNumber: null,
									zipCode: null,
									total: 0,
									funds: [{ fundType: '', amount: '', description: '' }],
									FormData: null,
									show: true,
									recurringwithLoginCheck: null,
									loader: false,
								});
								payment.data.paymentDetails.businessName = this.state.adminSetting.businessName;
								payment.data.paymentDetails.businessNumber = this.state.adminSetting.businessNumber;
								payment.data.paymentDetails.link = this.state.adminSetting.auth.route;
								this.props.history.push({
									pathname: 'receipt',
									query: { ...payment.data.paymentDetails },
								});
							}
						}
					}
				} else {
					// data.organization = this.state.adminSetting._id;
					// data.fundType = this.state.funds[0].fundType;
					data = {
						nonce: data.nonce,
						amount: this.state.total,
						description: '',
						fundType: this.state.funds[0].fundType,
						payment: payments,
						type: this.state.recurring ? this.state.recurringDuration : 'oneTime',
						email: this.state.email,
						country: this.state.country,
						region:this.state.region
					};
					// console.log(data);
					let paymentPost = await axios.post(`/payment/addSquareOneTime/${this.state.adminSetting._id}`, data);
					// console.log(paymentPost);
					if (paymentPost.status === 200 || paymentPost.status === 201) {
						this.setState({
							success: true,
							firstName: null,
							lastName: null,
							recurring: false,
							streetAddress: null,
							email: null,
							country: '',
							region: '',
							phoneNumber: null,
							zipCode: null,
							recurringwithLoginCheck: null,
							total: 0,
							funds: [{ fundType: '', amount: '', description: '' }],
							FormData: null,
							show: true,
							loader: false,
						});
						paymentPost.data.paymentDetails.businessName = this.state.adminSetting.businessName;
						paymentPost.data.paymentDetails.businessNumber = this.state.adminSetting.businessNumber;
						paymentPost.data.paymentDetails.link = this.state.adminSetting.auth.route;
						this.props.history.push({
							pathname: 'receipt',
							query: { ...paymentPost.data.paymentDetails },
						});
					}
				}

				this.setState({ FormData: data });
			}
		} catch (err) {
			this.setState({
				error: err.response.data.message,
			});
			console.log(err);
		}
	};
	getStripeFormData = async (data) => {
		let payments = this.state.funds.map((fund) => {
			if (fund.description !== '') {
				return { ...fund };
			} else {
				return { ...fund, description: '' };
			}
		});
		try {
			if (data) {
				// console.log('Stipe form Data', data);

				let { number, month, year, cvc } = data;

				// console.log(this.state.recurringwithLoginCheck);
				if (this.state.recurringwithLoginCheck === 'recurringWithoutLogin') {
					// const newUser = await axios.post('/auth/getAuthId', { adminId: this.state.adminSetting.auth._id });
					// console.log(newUser.data.auth._id);
					if (this.state.userAuthId !== '') {
						data = {
							cardNumber: number.split(' ').join(''),
							expMonth: this.state.monthArray.indexOf(month) + 1,
							expYear: year,
							cvc: cvc,
							authId: this.state.userAuthId,
							// auth: this.state.user ? this.state.user.id : null,
						};

						// data = {
						// 	authId: this.state.userAuthId,
						// 	payments: payments,
						// 	type: this.state.recurring ? this.state.recurringDuration : 'oneTime',
						// };

						let stripe = await axios.post('/stripeDetails/add', data);
						if (stripe.status === 201) {
							let paymentData = {
								authId: this.state.userAuthId,
								payments: payments,
								type: this.state.recurring ? this.state.recurringDuration : 'oneTime',
								email: this.state.email,
								country: this.state.country,
								region: this.state.region,
							};
							// console.log(stripe);
							let payment = await axios.post('/payment/addUserPayment/' + this.state.adminSetting._id, paymentData);
							// console.log(payment);
							if (payment.status === 200 || payment.status === 201) {
								this.setState({
									success: true,
									firstName: null,
									lastName: null,
									recurring: false,
									streetAddress: null,
									email: null,
									phoneNumber: null,
									error: '',
									country: '',
									region: '',
									zipCode: null,
									recurringwithLoginCheck: null,
									total: 0,
									funds: [{ fundType: '', amount: '', description: '' }],
									FormData: null,
									show: true,
								});
								payment.data.paymentDetails.businessName = this.state.adminSetting.businessName;
								payment.data.paymentDetails.businessNumber = this.state.adminSetting.businessNumber;
								payment.data.paymentDetails.link = this.state.adminSetting.auth.route;
								this.props.history.push({
									pathname: 'receipt',
									query: { ...payment.data.paymentDetails },
								});
							}
						}
					}
				} else {
					// console.log('=>', data);
					// console.log(this.state.funds);
					data.amount = this.state.total;
					data.description = '';
					data.organization = this.state.adminSetting._id;
					data.fundType = this.state.funds[0].fundType;
					data.type = this.state.recurring ? this.state.recurringDuration : 'oneTime';
					data.payment = payments;
					data.email = this.state.email;
					data.expMonth = this.state.monthArray.indexOf(month) + 1;
					data.expYear = year;
					data.country = this.state.country;
					data.region = this.state.region
					data.cardNumber = number.split(' ').join('');
					// console.log(data);
					let paymentPost = await axios.post(`/payment/addStripeOneTime/${this.state.adminSetting._id}`, data);
					// console.log(paymentPost);
					if (paymentPost.status === 200 || paymentPost.status === 201) {
						this.setState({
							success: true,
							firstName: null,
							lastName: null,
							recurring: false,
							streetAddress: null,
							country: '',
							region: '',
							email: null,
							phoneNumber: null,
							zipCode: null,
							error: '',
							total: 0,
							recurringwithLoginCheck: null,
							funds: [{ fundType: '', amount: '', description: '' }],
							FormData: null,
							show: true,
						});
						paymentPost.data.paymentDetails.businessName = this.state.adminSetting.businessName;
						paymentPost.data.paymentDetails.businessNumber = this.state.adminSetting.businessNumber;
						paymentPost.data.paymentDetails.link = this.state.adminSetting.auth.route;
						this.props.history.push({
							pathname: 'receipt',
							query: { ...paymentPost.data.paymentDetails },
						});
					}
				}

				this.setState({ FormData: data }, () => {
					// console.log('Final state', this.state);
				});
			}
		} catch (err) {
			this.setState({
				error: err.response.data.message,
			});
			// console.log(err.response);
		}
	};

	userPay = async () => {
		let payments;
		payments = this.state.funds.map((fund) => ({ ...fund, description: '' }));
		if (this.state.adminSetting.stripeEnabled) {
			let data = {
				authId: this.state.user.id,
				payments: payments,
				type: this.state.recurring ? this.state.recurringDuration : 'oneTime',
			};
			let payment = await axios.post('/payment/addUserPayment/' + this.state.adminSetting._id, data);
			// console.log(payment);
			if (payment.status === 200 || payment.status === 201) {
				this.setState((prevState) => {
					return {
						...prevState,
						success: true,
						firstName: null,
						lastName: null,
						recurring: false,
						streetAddress: null,
						email: null,
						country: '',
						region: '',
						phoneNumber: null,
						zipCode: null,
						total: 0,
						recurringwithLoginCheck: null,
						funds: [{ fundType: '', amount: '', description: '' }],
						FormData: null,
						show: true,
						error: '',
					};
				});
				// console.log(this.state.adminSetting);
				payment.data.paymentDetails.businessName = this.state.adminSetting.businessName;
				payment.data.paymentDetails.businessNumber = this.state.adminSetting.businessNumber;
				payment.data.paymentDetails.link = this.state.adminSetting.auth.route;
				this.props.history.push({
					pathname: 'receipt',
					query: { ...payment.data.paymentDetails },
				});
			}
		} else {
			let data = {
				authId: this.state.user.id,
				payments: payments,
				type: this.state.recurring ? this.state.recurringDuration : 'oneTime',
			};
			let payment = await axios.post('/payment/addUserPayment/' + this.state.adminSetting._id, data);
			// console.log(payment);
			if (payment.status === 200 || payment.status === 201) {
				this.setState({
					success: true,
					firstName: null,
					lastName: null,
					recurring: false,
					streetAddress: null,
					email: null,
					country: '',
					region: '',
					phoneNumber: null,
					zipCode: null,
					total: 0,
					recurringwithLoginCheck: null,
					funds: [{ fundType: '', amount: '', description: '' }],
					FormData: null,
					show: true,
					error: '',
				});
				payment.data.paymentDetails.businessName = this.state.adminSetting.businessName;
				payment.data.paymentDetails.businessNumber = this.state.adminSetting.businessNumber;
				payment.data.paymentDetails.link = this.state.adminSetting.auth.route;
				this.props.history.push({
					pathname: 'receipt',
					query: { ...payment.data.paymentDetails },
				});
			}
		}
	};
	handleCheck = (event) => {
		const { recurring, recurringwithLogin } = this.state;
		this.setState({ recurring: !recurring, recurringwithLoginCheck: null, recurringwithLogin: !recurringwithLogin });
	};
	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	handleAdd = () => {
		// console.log('State', this.state);
		var { funds, previousTotal } = this.state;
		let lastId = funds.length - 1;
		if (funds[lastId].amount !== null && funds[lastId].amount !== '') {
			funds.push({ fundType: '', amount: '' });
			this.setState({ funds });
			this.setState({ total: previousTotal });
		}
	};

	handleDelete = (index) => {
		const UpdateArray = this.state.funds.filter((s, sindex) => index !== sindex);
		var updateTotal = this.state.total;
		updateTotal = updateTotal - parseInt(this.state.funds[index].amount);
		// console.log('updateTotal', updateTotal);
		this.setState({ total: updateTotal });
		this.setState({ funds: UpdateArray });
	};

	handleFundChange = async (index, event) => {
		const UpdateArray = this.state.funds;
		if (UpdateArray[index].fundType !== '') {
			if (event.target.value > 0 || event.target.value === '') {
				UpdateArray[index].amount = isNaN(event.target.value) ? 0 : event.target.value;
				this.setState({ funds: UpdateArray }, () => {
					this.calculate_amount(this.state.previousTotal);
				});
			}
		}
	};

	handleCommentChange = async (index, event) => {
		const UpdateArray = this.state.funds;
		if (UpdateArray[index].fundType !== '') {
			if (event.target.value !== '') {
				UpdateArray[index].description = event.target.value;
				this.setState({ funds: UpdateArray }, () => {
					// console.log(this.state.funds);
				});
			}
		}
	};

	handleDuration = (duration) => {
		console.log('E', duration.target.value);
		this.setState({ recurringDuration: duration.target.value });
	};
	handleType = async (index, event) => {
		// console.log("Event ", event);
		const UpdateArray = this.state.funds;
		UpdateArray[index].fundType = event;
		this.setState({ funds: UpdateArray });
	};
	handleBlur = (idx, event) => {
		// console.log("BLUR", idx, event);
		const { funds } = this.state;
		var num = parseFloat(funds[idx].amount);
		var cleanNum = num.toFixed(2);
		funds[idx].amount = cleanNum;
		this.setState({ funds }, () => console.log(this.state.funds));
	};
	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({ missing: false });
		const { funds } = this.state;
		if (funds[0].amount !== null && funds[0].amount !== '') {
			this.setState({ show: false }, () => console.log('this.state', this.state));
		} else {
			this.setState({ missing: true });
		}
	};

	convert_float = async (value) => {
		return parseFloat(value);
	};
	calculate_amount = async () => {
		var sum1 = 0;

		const array = this.state.funds;
		const sum = array.map(async (element) => {
			var x = await this.convert_float(element.amount);
			// console.log('x type', x);
			// console.log('Y type', typeof y);
			// console.log('X type', typeof x);
			sum1 = sum1 + x;
			// console.log('Total', sum1);
			if (isNaN(sum1) || sum1 === 0) this.setState({ total: x });
			else
				this.setState({ total: sum1 }, () => {
					// console.log('After setting state', this.state.total);
				});
		});
	};
	changeForm = () => {
		var a = this.state.show;
		this.setState({ show: !a, error: '' });
	};

	handleRadio = async (event) => {
		// console.log('target.name', event.target.name);
		const target = event.target;
		if (target.value === 'recurringWithoutLogin') {
			const newUser = await axios.post('/auth/getAuthId', { adminId: this.state.adminSetting.auth._id });
			// console.log(newUser.data.auth._id);
			this.setState({ recurringwithLogin: false, userAuthId: newUser.data.auth._id });
		} else this.setState({ recurringwithLogin: true, userAuthId: '' });
		this.setState(
			{
				[target.name]: target.value,
			},
			() => {
				// console.log('State', this.state);
			},
		);
	};

	loaderSet = () => {
		this.setState((prevState) => {
			return {
				loader: !prevState.loader,
			};
		});
	};

	render() {
		if (this.state.adminSetting.email === null) {
			return <h1>Organization Details will be available soon</h1>;
		}
		if (this.state.show)
			return (
				<div>
					<Col>
						<Row>
							<Col md={12} lg={12} sm={12}>
								<Navbar user={this.state.user} adminSetting={this.state.adminSetting} />
							</Col>
						</Row>

						{this.state.success ? (
							<Row>
								<Col>
									<Alert variant='success'>Payment Successfull </Alert>
								</Col>
							</Row>
						) : null}
						<Row>
							<Col>
								<Form onSubmit={this.handleSubmit} style={{ minHeight: '100vh' }} className='customfont'>
									<Row style={{ }} className='ofr'>
										<Col className='' lg={12} md={12} xs={12}>
											<Col className='pl0' lg={6} md={6} xs={12} className='pl0 mt-3'>
												{this.state.funds.map((field, idx) => {
													return (
														<FundComponent
															adminSetting={this.props.admin}
															key={`${field}-${idx}`}
															onDelete={this.handleDelete}
															onFundChange={this.handleFundChange}
															onFundType={this.handleType}
															onBlurChange={this.handleBlur}
															idx={idx}
															field={field}
															handleCommentChange={this.handleCommentChange}
														/>
													);
												})}

												<MaterialButton className='pl0' onClick={this.handleAdd} style={{ paddingLeft: '15px' }} color='default' startIcon={<ControlPointIcon />}>
													<p style={{ paddingTop: '20px' }}>Add New Item</p>
												</MaterialButton>
												{this.state.missing && <Alert variant='danger'>Fund Type Or Amount Missing </Alert>}
												{this.state.total > 0 && (
													<Row className='mb-3'>
														<Col className='' lg={4} md={4} xs={5}>
															<h6
																style={{
																	margin: 0,
																	height: '100%',
																	display: 'flex',
																	alignItems: 'center',
																}}>
																{' '}
																Total Amount:
															</h6>
														</Col>

														<Col className='' lg={5} md={5} xs={5} style={{}}>
															<InputGroup>
																<InputGroup.Prepend>
																	<InputGroup.Text id='basic-addon1'>$</InputGroup.Text>
																</InputGroup.Prepend>
																<NumberFormat
																	customInput={Form.Control}
																	placeholder='Amount'
																	onBlur={(event) => {
																		this.props.onBlurChange(this.props.idx, event);
																	}}
																	disabled={true}
																	required
																	name='amount'
																	value={this.state.total}
																/>
															</InputGroup>
														</Col>
													</Row>
												)}

												{/* {console.log(this.state.funds)} */}

												<Row className='mb-2 ml-0'>
													<Col className='pl0' lg={10} md={12} xs={12}>
														<Form.Check type='checkbox' checked={this.state.recurring} label='Make this donation or payment a recurring amount each time.' onChange={this.handleCheck} />
													</Col>
												</Row>
												{this.state.recurring && (
													<Row className='mt-1 mt-2 mb-3 ml-0'>
														<Col className='pl0' md={12} xs={12} lg={12}>
															<Form.Control className='recurring-select' style={{}} as='select' onChange={this.handleDuration} custom>
																<option value='weeklyRecurring'>Weekly Recurring Payment</option>
																<option value='monthlyRecurring'>Monthly Recurring Payment</option>
															</Form.Control>
															{/* <DropdownButton
																onSelect={this.handleDuration}
																variant='info'
																// defaultChecked={this.state.recurringDuration}
																// defaultValue={this.state.recurringDuration}
																style={{
																	color: 'white',
																}}
																title={
																	this.state.recurringDuration ? <span style={{ color: 'white' }}>{this.state.recurringDuration === 'weeklyRecurring' ? 'Weekly Recurring Payment' : 'Monthly Recurring Payment'}</span> : <span style={{ color: 'white' }}>Select</span>
																}>
																<Dropdown.Item
																	style={{
																		backgroundColor: this.state.recurringDuration === 'weeklyRecurring' ? '#117a8b' : '#17a2b8',
																		color: this.state.recurringDuration === 'weeklyRecurring' ? 'black' : 'white',
																	}}
																	eventKey='weeklyRecurring'>
																	Weekly Recurring Payment
																</Dropdown.Item>
																<Dropdown.Item
																	style={{
																		backgroundColor: this.state.recurringDuration === 'monthlyRecurring' ? '#117a8b' : '#17a2b8',
																		color: this.state.recurringDuration === 'monthlyRecurring' ? 'black' : 'white',
																	}}
																	eventKey='monthlyRecurring'>
																	Monthly Recurring Payment
																</Dropdown.Item>
															</DropdownButton> */}
														</Col>
													</Row>
												)}

												{this.state.recurring && !this.state.User && (
													<Row className='pl0' className='mb-2 ml-0'>
														<Col className='pl0' md={12} xs={12} lg={12}>
															<h6>
																<Form.Check type='radio' name='recurringwithLoginCheck' label='Setup recurring payments without creating an account' value='recurringWithoutLogin' className='mt-1' onChange={this.handleRadio} />
															</h6>
														</Col>

														<Col className='pl0' md={12} xs={12} lg={12}>
															<p className='mt-1' style={{ fontSize: '80%' }}>
																(With this option, payments would occur same time every week/month.Payee will need to contact to the organization to update subscription,update credit card or to cancel subscription)
															</p>
														</Col>
													</Row>
												)}
												{this.state.recurring && !this.state.User && (
													<Row className='mb-2 ml-0'>
														<Col className='pl0' md={12} xs={12} lg={12}>
															<h6>
																<Form.Check type='radio' name='recurringwithLoginCheck' label='Setup recurring payments by creating an account' value='recurringWithLogin' className='mt-1' onChange={this.handleRadio} />
															</h6>
														</Col>

														<Col className='pl0' md={12} xs={12} lg={12}>
															<p className='mt-1' style={{ fontSize: '80%' }}>
																(With this option, payments would occur same time every week/month.Payee will manage update to credit card Information, update address, update subscription, cancel subscription, view history of transactions.)
															</p>
														</Col>

														{/* <Col md={12} xs={12} lg={12}>
															{!this.state.recurringwithLoginCheck && <Alert variant='danger'>Select Option </Alert>}
														</Col> */}
													</Row>
												)}
												{this.state.recurringwithLogin && !this.state.user && this.state.recurringwithLoginCheck === 'recurringWithLogin' && (
													<Row className='mb-2 ml-0'>
														<Col className='pl0' md={6} xs={6} lg={6}>
															<Link to={this.state.adminSetting.auth.route + '/signin'}>
																<Button variant='info' block>
																	login
																</Button>
															</Link>
														</Col>

														<Col md={6} xs={6} lg={6}>
															<Link to={this.state.adminSetting.auth.route + '/signup'}>
																<Button variant='info' block>
																	SignUp
																</Button>
															</Link>
														</Col>
													</Row>
												)}
											</Col>
										</Col>

										{!this.state.user ? (
											<Col lg={6} md={6} xs={12} className='mt-3'>
												<div style={{ backgroundColor: '#9FA8A3', padding: '1rem ' }}>
													<Row className='pb-2 pt-2 mb-2'>
														<Col>
															<Row>
																<Col md={10}>
																	<h3>Billing Information</h3>
																</Col>
															</Row>
															<br />
															<Row>
																<Col lg={6} md={6} xs={6}>
																	<Form.Control placeholder='First Name' type='text' value={this.state.firstName} name='firstName' onChange={this.handleChange} required />
																</Col>

																<Col lg={6} md={6} xs={6}>
																	<Form.Control placeholder='Last Name' type='text' required value={this.state.lastName} name='lastName' onChange={this.handleChange} />
																</Col>
															</Row>
															<Row className='mt-3 mb-3'>
																<Col lg={6} md={6} xs={6}>
																	<CountryDropdown className='country-dropdown' value={this.state.country} onChange={(val) => this.selectCountry(val)} />
																</Col>
																<Col lg={6} md={6} xs={6}>
																	<RegionDropdown className='region-dropdown' country={this.state.country} value={this.state.region} onChange={(val) => this.selectRegion(val)} />
																</Col>
															</Row>
															<Row className='mt-3 mb-3'>
																<Col lg={6} md={6} xs={6}>
																	<Form.Control placeholder='Street Address' type='text' required value={this.state.streetAddress} name='streetAddress' onChange={this.handleChange} />
																</Col>

																<Col lg={6} md={6} xs={6}>
																	<Form.Control placeholder='Zip Code/Postal Code' type='text' required value={this.state.zipCode} name='zipCode' onChange={this.handleChange} />
																</Col>
															</Row>
															<Row className='mt-2 mb-2'>
																<Col lg={6} md={6} xs={6}>
																	<Form.Control placeholder='Email Address' type='text' value={this.state.email} name='email' onChange={this.handleChange} required />
																</Col>

																<Col lg={6} md={6} xs={6}>
																	<Form.Control placeholder='Phone (optional)' type='text' value={this.state.phoneNumber} name='phoneNumber' onChange={this.handleChange} />
																</Col>
															</Row>
														</Col>
													</Row>
													<Row className='mt-4 '>
														<Col className=''>
															{console.log(this.state.recurring && this.state.recurringwithLoginCheck !== null)}
															<Button
																type='button'
																size='lg'
																block
																variant='info'
																disabled={this.state.total === 0 || this.state.firstName === null || this.state.lastName === null || this.state.email === null || (this.state.recurring && this.state.recurringwithLoginCheck === null)}
																onClick={() => {
																	this.setState({ show: false });
																}}>
																Next
															</Button>
														</Col>
													</Row>
												</div>
											</Col>
										) : (
											<Col lg={5} md={5} xs={8} className='mt-3 ml-3'>
												<Button type='button' disabled={this.state.total === 0} size='lg' block variant='info' onClick={this.userPay}>
													${this.state.total} Pay
												</Button>
											</Col>
										)}
									</Row>
								</Form>
							</Col>
						</Row>

						{!this.props.admin._id && (
							<Row>
								<Col>
									<h1>admin has not provided any information</h1>
								</Col>
							</Row>
						)}
					</Col>
				</div>
			);
		else if (!this.state.show)
			return (
				<Row>
					<Col md={12}>
						<Row className='p-0'>
							<Col md={12} lg={12} sm={12} className=''>
								<Navbar user={this.state.user} adminSetting={this.state.adminSetting} />
							</Col>
						</Row>
					</Col>

					<Col md={12}>
						{this.state.error ? (
							<Row style={{ marginTop: '.5rem' }}>
								<Col>
									<Alert variant='danger'>{this.state.error} </Alert>
								</Col>
							</Row>
						) : null}
						<Row style={{ minHeight: '100vh' }} lg={10} md={10} xs={10}>
							<Col lg={9} md={12} xs={12} className='mt-3'>
								{this.state.adminSetting.stripeEnabled && <StriptForm submitData={this.getStripeFormData} backButton={this.changeForm} total={this.state.total} loaderSet={this.loaderSet} />}
								{!this.state.adminSetting.stripeEnabled && (
									<SquareForm locationId={this.state.adminSetting.squareLocationId} accountId={this.state.adminSetting.squareAppId} details={this.state} total={this.state.total} backButton={this.changeForm} loaderSet={this.loaderSet} sentData={this.getSquareFormData} />
								)}
							</Col>

							<Col lg={5} md={5} xs={10} className='mt-3 ml-3'></Col>
						</Row>
					</Col>
				</Row>
			);
	}
}
export default withRouter(OrganizationHomePage);
