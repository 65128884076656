import axios from 'axios';
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
const instance = axios.create({
	// baseURL:'http://192.168.18.8:6001/myapp/api',
	// baseURL: 'http://127.0.0.1:6001/myapp/api/',
	baseURL: 'https://api.egive365.com/myapp/api/',
});
export default instance;

// export const LOGO_URL = 'http://localhost:6001';
export const LOGO_URL = 'https://api.egive365.com';
